<template>
  <div>
    <el-scrollbar class="main-content">
      <div class="back-btn-content">
        <div class="back-btn-self">
          <el-button type="primary" @click="goBack">返回</el-button>
        </div>
      </div>
      <div class="content">
        <el-form
          :model="addForm"
          :rules="rules"
          ref="addForm"
          label-width="100px"
          class="demo-ruleForm"
        >
          <el-form-item label="商品名称" prop="goods_name">
            <el-input
              v-model="addForm.goods_name"
              style="width:500px"
              maxlength="255"
              show-word-limit
              placeholder="请输入商品名称"
            ></el-input>
          </el-form-item>

          <el-form-item label="商品分类" prop="category_id">
            <el-select filterable v-model="addForm.category_id" placeholder="请选择商品分类" >
              <el-option :label="items.category_name" :value="items.category_id" v-for="(items,index) in categoryList" :key="`categoryList_${index}`"></el-option>
            </el-select>
          </el-form-item>

          <el-form-item
            label="商品图片"
            class="upload-wrapper"
            prop="goods_img"
          >
          <div class="upload-flex">
            <template v-for="imageIndex in 5">
              
            <el-upload
            
              class="img-uploader"
              :action="uploadCover"
              :headers="headersParams"
              name="goods_img"
              accept=".jpg, .jpeg, .png"
              :show-file-list="false"
              :on-success="function(res, file) {return handleCoverSuccess(res, file, imageIndex)}"
              :before-upload="beforeCoverUpload"
            >
              <img
                v-if="addForm.goods_img[imageIndex - 1]"
                :src="addForm.goods_img[imageIndex - 1]"
                class="img-box" 
              />
              <div class="add-box" v-if="addForm.goods_img[imageIndex - 1]">
                <div class="el-icon-delete" @click.stop="delFile(addForm.goods_img[imageIndex - 1])" style="font-size:20px">删除</div>
              </div>
              
              <div class="add-box" v-else>
                <i class="el-icon-plus add-icon"></i>
                <div class="add-text">上传图片</div>
              </div>
            </el-upload>
            
            </template>
            </div>
          </el-form-item>

          <el-form-item label="商品详情" prop="detail">
            <vue-tinymce :setting="setting" v-model="addForm.detail" style="width:80%"></vue-tinymce>
          </el-form-item>

          <el-form-item label="商品价格" prop="price">
            <el-input-number v-model="addForm.price" :min="0.01" :max="99999999.99" label="商品价格" :controls="false" class="text-price"></el-input-number>
            <span style="margin-left: 10px">元</span>
          </el-form-item>

         <el-form-item label="市场价格" prop="market_price">
            <el-input-number v-model="addForm.market_price" :min="0.01" :max="99999999.99" label="市场价格" :controls="false" class="text-price" @input="change_DisCountValue"></el-input-number>
            <span style="margin-left: 10px">元</span>
          </el-form-item>

          <el-form-item label="商品折扣">
            <el-input-number v-model="discount" :min="0.01" :max="99999999.99" label="商品折扣" :controls="false" class="text-price" :disabled="true"></el-input-number>
            <span style="margin-left: 10px">%</span>
          </el-form-item>

          <el-form-item label="商品库存" prop="stock">
            <el-input-number v-model="addForm.stock" :min="0.01" :max="99999999.99" label="商品库存" :controls="false" class="text-price"></el-input-number>
          </el-form-item>


          <el-form-item label="商品卖点" prop="selling_points">
            <el-input
              type="textarea"
              v-model="addForm.selling_points"
              maxlength="500"
              show-word-limit
              :rows="5"
              style="width:500px"
              placeholder="请描述商品卖点"
            ></el-input>
          </el-form-item>

          <el-form-item>
            <el-button type="primary" @click="submitForm('addForm')"
              >确认</el-button
            >
            <el-button @click="resetForm('addForm')">重置</el-button>
          </el-form-item>
        </el-form>
      </div>
    </el-scrollbar>
  </div>
</template>

<script>
import { goodsUploadGoodsImg,categoryList,goods_addGoods,goods_getgoods } from "@/config/apis.js";
export default {
  data() {
    return {
      discount:void 0,
      dialogVisible:false,
      uploadCover: goodsUploadGoodsImg(),
      headersParams: {
        Authorization: localStorage.getItem("schoolToken"),
      },
      addForm: {
        goods_img: [],
        goods_name: "",
        price: void 0,
        market_price:void 0,
        category_id:'',
        selling_points: "",
        stock:void 0,
        detail:"",
      },
      categoryList: [],
      rules: {
        goods_name: [
          {
            required: true,
            message: "请输入商品名称",
            trigger: "blur",
          },
          {
            min: 3,
            max: 255,
            message: "最少不能小于3个字符,最多不能超过255个字符",
            trigger: "blur",
          },
        ],
        category_id: [
          {
            required: true,
            message: "请选择商品类别",
            trigger: "change",
          },
        ],
        goods_img: [
          {
            required: false,
            message: "请上传商品图片",
            trigger: "blur",
          },
        ],
        selling_points: [
          {
            required: true,
            message: "请描述商品详情",
            trigger: "blur",
          },
        ],
        price:[
          {
            required: true, 
            message: "商品价格不能为空",
            trigger: "blur"
          }
        ],
        market_price:[
          {
            required: true, 
            message: "市场价格不能为空",
            trigger: "blur"
          },
        ],
        stock:[
          {
            required:true,
            message:"库存不能为空",
            trigger:'blur'
          }
        ],
        detail:[
          {
            required:true,
            message:'商品详情不能为空',
            trigger:'blur'
          }
        ]
      },
      setting: {
                    menubar: false,
                    plugins: "lists, advlist,emoticons,fullscreen,table,insertdatetime,wordcount,image",
                    toolbar: [
                        'undo redo | styleselect | bold italic underline strikethrough|fontselect fontsizeselect formatselect|alignleft aligncenter alignright|bullist numlist|',
                        'image emoticons forecolor backcolor insertdatetime wordcount|table tabledelete|fullscreen|'
                    ],
                    language: 'zh_CN', 
                    height: 300,
                },
    };
  },
  mounted () {
    this.showCategoryList();
    if(this.$route.query.id){
      this.queryGoodsByid()
    }
  },
  methods: {
    queryGoodsByid(){
        let params={
            goods_id:this.$route.query.id
        }
        goods_getgoods(params).then((res)=>{
            this.addForm = res.data
            this.addForm.goods_img = res.data.imgs.map(item=>{
              return item.url;
            })
            console.log(this.addForm.goods_img)
        })
    },
    delFile(file){
      this.addForm.goods_img=this.addForm.goods_img.filter((item)=>item!==file)
      this.$forceUpdate()
      this.$message.success('删除成功')

    },
    showCategoryList(){
     let params={
       paging:"0",
     }
     categoryList(params).then((res)=>{
       this.categoryList=res.data.list;

     }).catch(err => {})
    },
    goBack() {
      this.$router.go(-1);
    },
    change_DisCountValue(){
     this.discount=parseInt(this.addForm.price/this.addForm.market_price*100);
    },
    handleCoverSuccess(res, file, index) {
      if (res.code === 200) {
        // this.addForm.goods_img = res.data.filepath;
        this.$set(this.addForm.goods_img, index - 1, res.data.url)
        this.$forceUpdate()
        this.$message.success(res.msg);
      } else {
        this.$message.error("图片上传失败，请稍后再试~");
      }
    },
    beforeCoverUpload(file) {
      const isLt2M = file.size / 1024 / 1024 < 10;
      if (!isLt2M) {
          this.$message.error('上传头像图片大小不能超过 10MB!');
      }
      return isLt2M;
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let params={
            goods_name:this.addForm.goods_name,
            category_id:this.addForm.category_id,
            price:this.addForm.price,
            market_price:this.addForm.market_price,
            stock:this.addForm.stock,
            selling_points:this.addForm.selling_points,
            goods_img:JSON.stringify(this.addForm.goods_img),
            detail:this.addForm.detail,
          }
          if(this.$route.query.id){
            params.goods_id=this.$route.query.id
          }
          goods_addGoods(params).then((res)=>{
            this.$message.success(res.msg);
            this.$router.go(-1);
          })
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .main-content {
  margin: 20px;
  background-color: #fff;
  border: 1px solid #e6e6e6;
  height: calc(100% - 40px);
  display: flex;
  flex-direction: column;
  & > .el-scrollbar__wrap {
    overflow-x: hidden;
  }
  .back-btn-content {
    margin: 40px 40px 0 0;
    display: flex;
    justify-content: flex-end;
  }
  .content {
    flex: 1;
    height: 1%;
    padding: 50px;
    display: flex;
    flex-direction: column;
    .upload-flex{
      display: flex;
.img-uploader {
      width: 218px;
      height: 258px;
      background: #f6f7f7;
      border: 1px dashed #e5e5e5;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      margin-right: 10px;
      .img-box {
        display: block;
        max-width: 100%;
        max-height: 218px;
      }
      .add-box {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        line-height: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        
        .add-icon {
          color: #7d7d7d;
          font-size: 24px;
        }
        .add-text {
          color: #7d7d7d;
          font-size: 12px;
          margin-top: 12px;
        }
      }
    }
    }
    
  }
}

  ::v-deep .text-price{
    &.el-input-number .el-input__inner {
      text-align: left;
  }
}
</style>
